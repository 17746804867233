<template>
  <div class="agent-map">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="farmer-list">
      <h4>لیست کشاورزان تحت پوشش شما</h4>
      <h6>مجموع هکتار تحت پوشش : {{ sumAreas.toFixed(2) }} هکتار</h6>
      <div class="farmer-list-item">
        <div
          class="farmer-item"
          v-for="(item, index) of farmerList"
          :key="index"
          @click="GetFarms(item.id)"
          :class="selectedFarmerItem == item.id ? 'activeFarmer' : ''"
        >
          <ul>
            <li>
              <h4>نام و نام خانوادگی :</h4>
              <h5>{{ item.fullName }}</h5>
            </li>
            <li>
              <h4>شماره همراه :</h4>
              <h5>{{ item.phone }}</h5>
            </li>
            <li>
              <h4>تاریخ عضویت :</h4>
              <h5>{{ item.createDate }}</h5>
            </li>
            <li>
              <h4>تعداد زمین :</h4>
              <h5>{{ item.farmCount }} قطعه</h5>
            </li>
          </ul>
          <!-- <div class="farmer-item-action">
            <button>زمین ها ...</button>
            <button>مشخصات ...</button>
          </div> -->
        </div>
      </div>
    </div>
    <div id="map"></div>

    <VueModal
      :title="modalTitle"
      v-model="modalState"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      :enable-close="true"
      modalStyle="max-width: 60vw; width:unset; min-width: 40vw;"
    >
      <detail :key="selectedFarmItem" :farmId="selectedFarmItem" />
    </VueModal>
  </div>
</template>
    
<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapActions } from "vuex";
import polyGonStructure from "../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import { apiUrlRoot } from "../../constants/config";
import detail from "../farm/farmDetail.vue";

export default {
  components: { detail },
  data() {
    return {
      map: null,
      modalState: false,
      modalTitle: "",
      loading: false,
      loadingText: "",
      farmsList: [],
      farmerList: [],
      farmerFarmList: [],
      selectedFarmItem: 0,
      selectedFarmerItem: 0,
      sumAreas: 0,
    };
  },
  computed: {
    title() {
      return this.farmItem.title;
    },
    farmFields() {
      return [
        { label: "نوع محصول", value: this.farmItem.productType },
        { label: "مساحت", value: `${this.farmItem.farmArea} هکتار` },
        { label: "نوع کشت", value: this.farmItem.cultivationsTypeTitle },
      ];
    },
    address() {
      return this.farmItem.address;
    },
    farmImage() {
      // Use the provided image if it exists, otherwise fallback to a default
      return this.farmItem.productImage
        ? `${apiUrlRoot}ProductImage/${this.farmItem.productImage}`
        : "https://picsum.photos/200";
    },
  },
  methods: {
    ...mapActions("farm", ["Gets"]),
    ...mapActions("connectionAgent", [
      "GetAllAgentFarmers",
      "GetFarmerAllFarms",
    ]),
    async GetAllFarms() {
      this.farmsList = [];
      this.sumAreas = 0;
      this.loading = true;
      let result = await this.Gets({
        farmName: "",
        farmerPhone: "",
        archive: false,
      });
      if (result) {
        this.farmsList = result.data.map((item, id) => {
          let polygon = item.area.substring(
            item.area.indexOf("("),
            item.area.indexOf(")")
          );
          polygon = polygon.replaceAll("(", "");
          polygon = polygon.replaceAll(")", "");
          polygon = polygon.split(", ");
          polygon = polygon.map((item) => item.split(" "));
          const newArray = [polygon];
          polyGonStructure.features[0].geometry.coordinates = newArray;
          let farmArea = turf.area(polyGonStructure.features[0].geometry);
          item.farmArea = Intl.NumberFormat("fa").format(
            (farmArea / 10000).toFixed(2)
          );
          this.sumAreas += farmArea / 10000;

          let coordFinder = /\(\s?(\S+)\s+(\S+)\s?\)/g;
          let lnglat = coordFinder.exec(item.coordination);
          let marker = new mapboxgl.Marker({
            draggable: false,
            color: "green",
          })
            .setLngLat([lnglat[1], lnglat[2]])
            .addTo(this.map);
          marker._element.setAttribute("farm_id", item.farmId);
          marker._element.setAttribute("farm_title", item.title);
          marker.getElement().addEventListener("click", (el) => {
            this.selectedFarmItem = marker.getElement().getAttribute("farm_id");
            if (this.selectedFarmItem > 0) {
              this.modalTitle = `جزئیات زمین ${marker
                .getElement()
                .getAttribute("farm_title")}`;
              this.modalState = true;
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `جزئیات زمین یافت نشد`,
                type: "error",
              });
            }
          });

          return { ...item, id };
        });
      }
      await this.GetAllFarmer();
      this.loading = false;
    },
    async GetAllFarmer() {
      this.farmerList = [];
      this.loading = true;
      let result = await this.GetAllAgentFarmers({
        searchName: "",
      });
      if (result.succeeded) {
        this.farmerList = result.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
    },
    GetFarms(farmerId) {
      this.selectedFarmerItem = farmerId;
      let result = this.farmsList.filter((item) => item.farmerId == farmerId);
      var bounds = new mapboxgl.LngLatBounds();

      if (result.length == 0) {
        this.$notify({
          title: "پیام سیستم",
          text: `کشاورز هیچ زمین زیر کشتی ندارد`,
          type: "info",
        });
      } else if (result.length == 1) {
        let lng = result[0].coordination
          .substring(2, result[0].coordination.length - 1)
          .split(" ")[1]
          .substring(1, 8);
        let lat = result[0].coordination
          .substring(1, result[0].coordination.length - 1)
          .split(" ")[2]
          .substring(0, 7);
        bounds.extend([lng, lat]);
      } else if (result.length > 1) {
        result.forEach((element) => {
          let lng = element.coordination
            .substring(2, element.coordination.length - 1)
            .split(" ")[1]
            .substring(1, 8);
          let lat = element.coordination
            .substring(1, element.coordination.length - 1)
            .split(" ")[2]
            .substring(0, 7);
          bounds.extend([lng, lat]);
        });
      }
      if (result.length > 0) {
        this.map.fitBounds(bounds, {
          padding: 260,
          animate: true,
          maxZoom: 15,
          duration: 5000,
        });
      }
    },
  },
  mounted() {
    this.loading = true;
    this.loadingText = "درحال بارگذاری نقشه";
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";

    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }

    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      center: [53.126, 33.593],
      zoom: 5,
    });
    this.map.on("load", () => {
      this.GetAllFarms();
    });
  },
};
</script>
    
<style scoped>
.agent-map {
  width: 100%;
  height: 100%;
  position: relative;
}
.farmer-list {
  width: 20vw;
  overflow-y: auto;
  position: absolute;
  z-index: 2;
  top: 4vh;
  right: 2vw;
  background-color: #ffffffc2;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 10px;
}
.farmer-list > h4 {
  font-size: 14px;
  text-align: center;
  margin: 0px;
  color: #f80;
  font-weight: bold;
  background-color: #fff;
  padding: 4px 0px;
  border-radius: 6px;
}
.farmer-list h6 {
  font-size: 12px;
  text-align: right;
  margin: 6px 4px;
  color: #455a64;
  font-weight: bold;
}
.farmer-list-item {
  flex-grow: 1;
  border-right: 1px solid #f80;
  max-height: calc(100vh - 60vh);
  overflow-y: auto;
}
.farmer-item {
  background-color: #fff;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
}
.farmer-item:hover {
  background-color: #81c784;
}
.activeFarmer {
  background-color: #81c784;
}
.farmer-item ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 6px;
  padding: 13px;
}
.farmer-item ul li {
  border-bottom: 1px dashed #bbb;
  display: flex;
  justify-content: space-between;
  margin: 3px 0px;
}
.farmer-item ul:last-child {
  border-bottom: none;
}
.farmer-item ul li h4 {
  font-size: 12px;
  font-weight: bold;
}
.farmer-item ul li h5 {
  font-size: 12px;
}

.farmer-item-action {
  display: flex;
  justify-content: flex-end;
  gap: 3px;
  margin: 6px;
}
.farmer-item-action > button {
  border: none;
  background-color: #f80;
  color: #000;
  border-radius: 10px;
  width: 35%;
  margin-bottom: 5px;
}
#map {
  width: 99%;
  height: 97%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  border-radius: 10px;
  z-index: 1;
}
</style>