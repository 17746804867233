<template>
  <CRow class="row position-relative">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCol col="12" lg="5">
      <img
        class="map-img w-100 h-100"
        :src="farmImage"
        @error="() => (farmImage = require(`@/assets/img/farmImageMapBox.jpg`))"
        alt=""
      />
    </CCol>
    <CCol col="12" lg="7">
      <div class="farmInfoBox">
        <img
          :src="productImage"
          @error="
            () => (productImage = require(`@/assets/img/farmImageMapBox.jpg`))
          "
          class="productImage"
          alt=""
        />
        <div
          class="farmInfoBoxItem"
          v-if="
            userType == 'Advicer' ||
            userType == 'FarmUser' ||
            userType == 'Agency'
          "
        >
          <h6>نام مالک:</h6>
          <h5>{{ farmerName }}</h5>
        </div>
        <div class="farmInfoBoxItem" v-else>
          <h6>نام زمین:</h6>
          <h5>{{ title }}</h5>
        </div>
        <div class="farmInfoBoxItem">
          <h6>محصول:</h6>
          <h5>{{ productName }}</h5>
        </div>
        <div class="farmInfoBoxItem">
          <h6>نوع محصول:</h6>
          <div class="y-center-g-5">
            <h5>{{ productType }}</h5>
          </div>
        </div>
        <div class="farmInfoBoxItem">
          <h6>نوع کشت / تاریخ کشت:</h6>
          <h5>{{ cultivationsTypeTitle }} / {{ cultivationsDate }}</h5>
        </div>
        <div class="farmInfoBoxItem">
          <h6>مساحت:</h6>
          <h5>{{ farmArea }} هکتار</h5>
        </div>
        <div class="farmInfoBoxItem y-center-g-5">
          <h6>آدرس:</h6>
          <h6>
            {{ stateName }} - {{ cityName }} - {{ district }} - {{ village }}
          </h6>
        </div>
        <div class="farmInfoBoxItem">
          <h6>تاریخ پایان کشت:</h6>
          <h5 v-if="userType != 'Farmer'">{{ cultivationsEndDate ?? 'ثبت نشده است' }}</h5>
          <template v-else>
            <h5 v-if="cultivationsEndDate">{{ cultivationsEndDate }}</h5>
            <div v-else class="y-center-g-5">
              <CButton
                color="primary"
                class="end-cultivation-date-input"
                @click="showDatePicker = true"
                v-if="!showDatePicker"
              >
                تاریخ پایان کشت ثبت نشده است
              </CButton>
              <date-picker
                v-else
                color="var(--secondary)"
                class="animate__animated animate__bounceIn"
                v-model="newCultivationsEndDate"
                @close="confirmDate"
              />
            </div>
          </template>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions } from "vuex";
import polyGonStructure from "../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import { apiUrlRoot } from "../../constants/config";
import { getCurrentUser } from "../../utils/index";
import * as moment from "jalali-moment";

export default {
  name: "DetailForm",
  props: ["farmId"],
  data() {
    return {
      farmerName: null,
      title: null,
      productType: null,
      productName: null,
      cultivationsTypeTitle: null,
      cultivationsDate: null,
      cultivationsEndDate: null,
      newCultivationsEndDate: null,
      stateName: null,
      cityName: null,
      district: null,
      village: null,
      farmArea: 0,
      farmImage: "",
      productImage: "",
      loading: false,
      loadingText: "در حال دریافت اطلاعات زمین ... ",
      userType: null,
      showDatePicker: false,
    };
  },
  watch: {
    farmId: function () {
      this.fetchFarm();
    },
  },
  components: {},
  methods: {
    ...mapActions("farm", ["Get", "updateCultivationsEndDate"]),
    async fetchFarm() {
      this.loading = true;
      const result = await this.Get({ id: this.farmId });
      this.farmerName = result.farmerName;
      this.title = result.title;
      this.productType = result.productType;
      this.productName = result.productName;
      this.cultivationsTypeTitle = result.cultivationsTypeTitle;
      this.cultivationsDate = result.cultivationsDate;
      this.cultivationsEndDate = result.cultivationsEndDate;
      this.newCultivationsEndDate = null;
      this.stateName = result.stateName;
      this.cityName = result.cityName;
      this.district = result.district;
      this.village = result.village;
      this.farmImage = result.farmImage
        ? `${apiUrlRoot}Farm/${result.farmImage}`
        : "../../assets/img/farmImageMapBox.jpg";
      let polygon = result.area.substring(
        result.area.indexOf("("),
        result.area.indexOf(")")
      );
      this.productImage = `${apiUrlRoot}ProductImage/${result.productImage}`;
      polygon = polygon.replaceAll("(", "");
      polygon = polygon.replaceAll(")", "");
      polygon = polygon.split(", ");
      polygon = polygon.map((item) => item.split(" "));
      polyGonStructure.features[0].geometry.coordinates = [polygon];
      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.farmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      this.loading = false;
    },
    async addCurrentDateTime() {
      await this.newCultivationDate(moment().locale("fa").format("YYYY/MM/DD"));
    },
    async addUserDateTime() {
      await this.newCultivationDate(this.newCultivationsEndDate);
    },
    async newCultivationDate(datetime) {
      this.$confirm({
        message: ` ${datetime} به عنوان تاریخ پایان کشت زمین ثبت شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            let result = await this.updateCultivationsEndDate({
              farmId: this.farmId,
              datetime: datetime,
            });
            this.loading = false;
            if (result.succeeded) {
              this.cultivationsEndDate = datetime;
              this.$notify({
                title: "پیام سیستم",
                text: `تاریخ اتمام کشت ثبت شد <br /><br />`,
                type: "success",
              });
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message}  <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
    confirmDate() {
      if (this.newCultivationsEndDate) {
        this.showDatePicker = false;
        this.$confirm({
          message: ` ${this.newCultivationsEndDate} به عنوان تاریخ پایان کشت زمین ثبت شود؟`,
          button: {
            no: "لغو",
            yes: "بله",
          },
          callback: (confirm) => {
            if (confirm) {
              this.addUserDateTime();
            }
          },
        });
      }
    },
  },
  mounted() {
    this.userType = getCurrentUser().type;
    this.fetchFarm();
  },
};
</script>

<style>
.end-cultivation-date-input {
  width: 100%;
  cursor: pointer;
}
.end-cultivation-date-input input {
  background-color: var(--primary) !important;
  color: white !important;
}
.vm-titlebar {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
</style>
<style scoped>
.form-group {
  margin-bottom: 0 !important;
}

.farmInfoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  height: 100%;
  position: relative;
}

.farmInfoBoxItem {
  display: grid;
  gap: 10px;
  align-items: baseline;
  grid-template-columns: 1fr 2fr;
}

.productImage {
  width: 80px;
  height: 80px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 20px;
}

.map-img {
  object-fit: cover;
  border-radius: var(--border-radius);
}
</style>
